<template>
  <LayoutMainPages>
    <div class="flex flex-col">
      <WalletBalanceHeader @onTriggerAction="goToPayment" />
      <WalletTransactions parentRoute="wallet" />
    </div>
  </LayoutMainPages>
</template>

<script>
import { mapActions } from 'vuex';

import { LayoutMainPages } from '@/components/misc';
import { 
  WalletBalanceHeader,
  WalletTransactions,
} from '@/modules/walletv2';

export default {
  name: 'Wallet',

  components: {
    LayoutMainPages,
    WalletBalanceHeader,
    WalletTransactions,
  },
  
  async mounted() {
    if (!this.globalIsLoading) {
      await this.loaded();
    }

    await this.$store.dispatch('events/track', {
      event: 'WALLET_VIEWED',
    });
  },

  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },

  methods: {
    ...mapActions('ui', [
      'showPrompt',
      'showToast',
    ]),

    goToPayment(action) {
      this.showModal('PaymentInterface', { isDeposit: action === 'deposit' });
    },

    async loaded() {
      if (this.$route.name === 'deposit') {
        this.goToPayment('deposit');
      }
    },
  }
};
</script>